import { createBrowserRouter } from 'react-router-dom';
import RoutingErrorFallback from './components/RoutingErrorFallback';
import Logout from './auth/Logout';

import Menu from './Menu';

const router = createBrowserRouter([
  { path: '/', element: <Menu />, errorElement: <RoutingErrorFallback /> },
  { path: '/login', lazy: () => import('./auth/Login') },
  { path: '/logout', element: <Logout /> },
  { path: '/v1bridge', lazy: () => import('./v1bridge/V1Bridge') },
  {
    path: '/subscriber_settings',
    lazy: () => import('./subscription/SubscriberSettings')
  },
  { path: '/dashboards', lazy: () => import('./dashboard/DashboardList') },
  { path: '/dashboard/:id', lazy: () => import('./dashboard/Dashboard') },
  { path: '/form', lazy: () => import('./dynamicdata/CustomFormList') },
  { path: '/productsv2', lazy: () => import('./product/ProductList') },
  { path: '/productv2/new', lazy: () => import('./product/Product') },
  { path: '/productv2/:id', lazy: () => import('./product/Product') },
  { path: '/dealernetworks', lazy: () => import('./distributor/DistributorList') },
  { path: '/distributornetworks', lazy: () => import('./distributor/DistributorList') },
  { path: '/dealernetwork/new', lazy: () => import('./distributor/DistributorDetails') },
  { path: '/distributornetwork/new', lazy: () => import('./distributor/DistributorDetails') },
  { path: '/dealernetwork/:id', lazy: () => import('./distributor/DistributorDetails') },
  { path: '/distributornetwork/:id', lazy: () => import('./distributor/DistributorDetails') },
  { path: '/targets', lazy: () => import('./target/TargetList') },
  { path: '/tagging', lazy: () => import('./planogram/TaggingList') },
  { path: '/training', lazy: () => import('./planogram/ModelTraining') },
  { path: '/import/:entityType', lazy: () => import('./import/Import.js') },
  { path: '/bulkoperations', lazy: () => import('./queue/QueueList.js') },
  { path: '/tagging/tagmanagment/', lazy: () => import('./planogram/TagManagmentList.js') },
  { path: '/prediction/', lazy: () => import('./planogram/TaggingList') },
  { path: '/planogram/', lazy: () => import('./planogram/TaggingList') },
  { path: '/mlproducts/', lazy: () => import('./planogram/MLProductList.js') },
  { path: '/warrantyperiods/', lazy: () => import('./bip/WarrantyPeriodList.js') }
]);

export default router;
